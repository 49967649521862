import request from '@/utils/request'
//  获取用户信息 
export function getUserInfo(params) {
  return request({
    url: '/app-api/member/user/get',
    method: 'get',
    params
  })
}
// 修改用户信息 /app-api/member/user/profile/update
export function updatrUser(data) {
  return request({
    url: '/app-api/member/user/update',
    method: 'put',
    data
  })
}

// 获得积分兑换表-展示用分页 
export function pointList(params) {
  return request({
    url: '/app-api/eye/point-exchange-show/page',
    method: 'get',
    params
  })
}

// 创建系统用户（微信）绑定设备 /app-api/eye/sysuser-device-bind/create
export function bindDevice(data) {
  return request({
    url: '/app-api/eye/sysuser-device-bind/create',
    method: 'post',
    data
  })
}

// 获得系统用户（微信）绑定设备分页 /app-api/eye/sysuser-device-bind/page
export function bindDevicePage(params) {
  return request({
    url: '/app-api/eye/sysuser-device-bind/page',
    method: 'get',
    params
  })
}

// 获得护眼仪设备绑定使用者分页 /app-api/eye/device-user-bind/page
export function deviceUserPage(params) {
  return request({
    url: '/app-api/eye/device-user-bind/page',
    method: 'get',
    params
  })
}

// v1.5 用户列表 /app-api/vision/sysuser-sub-user/page
export function deviceNewUserPage(params) {
  return request({
    url: '/app-api/vision/sysuser-sub-user/page',
    method: 'get',
    params
  })
}

// AI 检测结果 取得用户的AI检查记录，集合=0：未绑定设备，集合=1首次使=检测，集合>1多次检测 devUserId id
// /app-api/eye/ai-check/getUserAiCheck
export function getUserAiCheck(params) {
  return request({
    url: '/app-api/eye/ai-check/getUserAiCheck',
    method: 'get',
    params
  })
}

// 获得护眼计划执行分页 根据 周开始时间、周结束时间、设备ID、使用者ID 获取护眼记录图标数据
export function getWeekPlanDetail(params) {
  return request({
    url: '/app-api/eye/care-plan/getWeekPlanDetail',
    method: 'get',
    params
  })
}

// 系统用户（微信）绑定设备列表-不分页，全部数据 
export function deviceAllList(params) {
  return request({
    url: '/app-api/eye/sysuser-device-bind/allList',
    method: 'get',
    params
  })
}

// 获得护眼仪积分商城商品分页 /app-api/eye/store-prod/page
export function goodList(params) {
  return request({
    url: '/app-api/eye/store-prod/page',
    method: 'get',
    params
  })
}

// 创建用户积分兑换记录 
export function exchangeGood(data) {
  return request({
    url: '/app-api/eye/user-point-exchange/exchangeProd',
    method: 'post',
    data
  })
}

// 获得用户积分兑换记录分页 /app-api/eye/user-point-exchange/page
export function exchangeList(params) {
  return request({
    url: '/app-api/eye/user-point-exchange/page',
    method: 'get',
    params
  })
}

// 微信授权通过code 获得用户基本信息 /member/oauth2/authMp
export function getWxUserByCode(data) {
  return request({
    url:`/app-api/member/auth/social-login`,
    method: 'post',
    data
  })
}

// 社交授权的跳转 /app-api/member/auth/social-auth-redirect
export function authRedirect(params) {
  return request({
    url: '/app-api/member/auth/social-auth-redirect',
    method: 'get',
    params
  })
}

// 获取设备截图 http://192.168.50.135:48080/app-api/eye/device/screen/shot?token=b8a1433be696495db90a055352482560
export function devicePic(data) {
  return request({
    url: '/app-api/eye/device/screen/shot',
    method: 'post',
    data
  })
}

// 关机 http://192.168.50.135:48080/app-api/eye/device/power/off?token=b8a1433be696495db90a055352482560
export function deviceOff(data) {
  return request({
    url: '/app-api/eye/device/power/off',
    method: 'post',
    data
  })
}

// 更新护眼仪使用者 /app-api/eye/device-user/update
export function updateDeviceUser(data) {
  return request({
    url: '/app-api/eye/device-user/update',
    method: 'put',
    data
  })
}

// 获取 jsAPI签名 /app-api/mp/open/jsapi
export function wxJsApi(data) {
  return request({
    // url: `/admin-api/mp/open/jsapi`,
    url: `/app-api/member/auth/create-weixin-jsapi-signature?url=${data.url}`,
    method: 'post',
    // params
    data
  })
}
// wxJsPay
export function wxJsPay(data) {
  return request({
    url: `/app-api/pay/order/submit-lease`,
    method: 'post',
    // params
    data
  })
}
// 解绑设备 /eye/sysuser-device-bind/delete
export function delDev(params) {
  return request({
    url: `/app-api/eye/sysuser-device-bind/delete?id=${params.id}`,
    method: 'delete',    
  })
}


// 3.加一个根据token和sn查询用户是否已绑定该设备
export function getBySN(params) {
  return request({
    url: '/app-api/eye/sysuser-device-bind/getBySN',
    method: 'get',
    params
  })
}
