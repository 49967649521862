import wx from 'weixin-js-sdk';
import { Toast } from 'vant';

// 微信公众号支付
export const wxpay = {
  install: (Vue) => {
    Vue.prototype.$wxpay = (msg1, msg, cb) => {
      // console.log(msg1, msg , '=== wx 1 ====');
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，
        // 参数信息会通过log打出，仅在pc端时才会打印。
        appId: msg1.appId, // 必填，公众号的唯一标识
        timestamp: msg1.timestamp, // 必填，生成签名的时间戳
        nonceStr: msg1.nonceStr, // 必填，生成签名的随机串
        signature: msg1.signature, // 必填，签名，见附录1
        jsApiList: [
          'chooseWXPay'// 微信支付
        ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });
      wx.ready(function() {
        wx.chooseWXPay({
          timestamp: msg.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: msg.nonceStr, // 支付签名随机串，不长于 32 位
          package: msg.packageValue,
          signType: 'MD5', // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: msg.paySign, // 支付签名
          success: function (res) {
            // 支付成功后的回调函数
            if (res.errMsg === 'chooseWXPay:ok') {
              // 支付成功
              /*eslint-disable */
              console.log('=== 用户付款 3 ====');
              cb('success')
            } else {
              console.log('=== 用户付款 2 ====');
              Toast(res.errMsg)
              cb('false')
            }
          },
          cancel: function (res) {
            console.log('=== 用户付款 4 ====');
            // 支付取消
            Toast('支付取消')
            cb('false');
          }
        });  
      });
      wx.error(function(res) {
        cb('false');
        console.log(res, '--- wxerror --');       
      });
    }
  }
}